import React from 'react';
import {css} from '@emotion/core';
import MapquestLogoSmall from '../assets/svg/mapquest-logo-black.svg';

const HowToUninstall = () => {
    return (
        <aside>
            <MapquestLogoSmall
                css={css`
                    margin-top: 20px;
                    margin-left: 20px;
                `}
            />
            <aside
                css={css`
                    color: black;
                    width: 1000px;
                    margin: auto;
                `}
            >
                <h1>
                    If you would like to remove the Mapquest Directions Newtab Extension from your browser, please
                    follow the instructions below:
                </h1>
                <p>
                    Our browser extension was installed on your browser through the Google Chrome Store, which means it
                    was reviewed thoroughly and abides by all of Google Chrome’s policies on usage, privacy,
                    functionality, and more. Additionally, there are various settings and configurations you are able to
                    adjust, to customize your experience.
                </p>
                <p>
                    To easily uninstall or disable the extension, you can visit the extension management section in your
                    browser’s settings menu. Here’s are the directions:
                </p>
                <h2>Google Chrome</h2>
                <p>
                    1. In Chrome&apos;s address bar (“omnibox”), type chrome://extensions and then press the Enter (PC)
                    or Return (Mac) key to open Chrome&apos;s Extensions manager.
                </p>
                <p>
                    2. Click the Remove button for an extension to uninstall it, or toggle its switch to turn off the
                    extension without removing it.
                </p>
                <p>
                    3. Alternatively, you can right-click the Mapquest Directions Newtab extension icon to the right of
                    the address bar (if you don’t see it, click the Chrome “puzzle” piece and then click the 3 dots next
                    to our extension), and select “Remove from Chrome”.
                </p>
                <p>
                    Once removed or disabled, our extensions will not function anymore. If you’re still having issues,
                    try restarting your browser. Please email us (extensions@mapquest.com) if you have further
                    questions.
                </p>
            </aside>
        </aside>
    );
};

export default HowToUninstall;
